<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 my-1">
                            <label for="start">Dê</label>
                            <input id="start" name="start" type="date" class="form-control" v-model="start">
                        </div>
                        <div class="col-md-3 my-1">
                            <label for="end">Até</label>
                            <input id="end" name="end" type="date" class="form-control" v-model="end">
                        </div>
                        <div class="col-md-3 float-left mt-auto">
                            <button type="submit" class="btn btn-outline-danger m-1" @click="resetFilter">Limpar
                            </button>
                            <button type="submit" class="btn btn-outline-primary m-1" @click="getData">Filtrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 v-step-0">
            <div class="card border-1 border-left-3 border-left-primary text-center">

                <div class="card-body">
                    <h4 class="mb-0" v-if="cards.value_total_sales !== null">
                        R$ {{
                            parseFloat(cards.value_total_sales).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        }}
                    </h4>
                    <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                    <div>Valor de vendas</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card border-1 border-left-3 border-left-primary text-center">

                <div class="card-body">
                    <h4 class="mb-0" v-if="cards.total_sales !== null">{{ cards.total_sales }}</h4>
                    <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                    <div>Total de vendas</div>
                </div>

            </div>
        </div>
        <div class="col-lg-3">
            <div class="card border-1 border-left-3 border-left-primary text-center">

                <div class="card-body">
                    <h4 class="mb-0" v-if="cards.total_mentorings !== null">{{ cards.total_mentorings }}</h4>
                    <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                    <div>Quantidade de Mentorias</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card border-1 border-left-3 border-left-primary text-center">

                <div class="card-body">
                    <h4 class="mb-0" v-if="cards.total_mentees !== null">{{ cards.total_mentees }}</h4>
                    <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                    <div>Total de Mentorados</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {addZeros} from "@/components/composables/masks";
import http from "@/http";

export default {

    data() {
        return {
            cards: {
                value_total_sales: null,
                total_sales: null,
                total_mentorings: null,
                total_mentees: null
            },
            start: '',
            end: ''
        }
    },

    methods: {
        getData() {
            http.get(`mentors/dashboard?start=${this.start}&end=${this.end}`)
                .then(response => {
                    this.cards = response.data;
                })
        },
        resetFilter() {
            const date = new Date();
            this.start = `${date.getFullYear()}-${addZeros(date.getMonth() + 1)}-01`;
            this.end = '';
            this.getData()
        }
    },

    mounted() {
        this.resetFilter();
    },
}
</script>
